<template>
  <div>
    <TopBar />
    <div class="main-container">
      <div class="p-grid">
        <div class="p-col-10 p-m-2">
          <Card class="helps-card">
            <template slot="header">
              <h1 class="helps-header">FAQs</h1>
            </template>
            <template slot="content">
              <div class="p-grid p-m-0">
                <div class="p-col-3 sidebar-nav">
                  <nav class="p-d-flex p-flex-column">
                    <router-link
                      class="sidebar-nav-link"
                      to="/help/introductions"
                      >Introductions</router-link
                    >
                    <router-link class="sidebar-nav-link" to="/help/onboarding"
                      >Onboarding</router-link
                    >
                    <router-link class="sidebar-nav-link" to="/help/guide"
                      >Guide</router-link
                    >
                    <router-link class="sidebar-nav-link" to="/help/roles"
                      >User Roles</router-link
                    >
                    <router-link class="sidebar-nav-link" to="/help/contact"
                      >Contact Us</router-link
                    >
                  </nav>
                </div>
                <div class="p-col-9 helps-content">
                  <router-view></router-view>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    TopBar,
  },
})
export default class Helps extends Vue {
  public mounted() {
    window["analytics"]["page"]("/help");
  }
}
</script>

<style>
.helps-header {
  margin: 0;
  padding: 1.7rem 2.5rem;
  border-bottom: 1px solid #ebeef5;
}

.helps-content {
  padding: 2.5rem;
}
</style>
